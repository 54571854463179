import React from "react";
import styles from "./styles.module.css";
import { Data } from "../../resources/dummyData";
import { useNavigate } from "react-router";
const Home = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.containerStyle}>
      <div className={styles.titleTextViewStyle}>
        <p className={styles.titleStyle}>All Categories</p>
      </div>
      <div className={styles.categoriesViewStyle}>
        {Data.map((item, index) => (
          <div
            key={index}
            className={styles.categoriesSubViewStyle}
            onClick={() => navigate("/list", { state: item })}
          >
            <p className={styles.categoryTextStyle}>{item.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Home;
