export const Data = [
  {
    name: "Mobiles",
    data: [
      {
        name: "oppo",
        url: "https://www.flipkart.com/oppo-reno-10-ice-blue-256-gb/p/itm9b49485a7c93e?pid=MOBGQP3UUYGFGYKD&param=398765",
        image:
          "https://www.gonnaorder.com/wp-content/uploads/2021/08/GoCustomer-Home_google-pixel5-sortasage-portrait.png",
        price: "30000",
      },
      {
        name: "poco",
        url: "https://www.flipkart.com/poco-x5-pro-5g-yellow-256-gb/p/itm3a4010e6b2c40?pid=MOBGMDKQFCHMDPRJ&lid=LSTMOBGMDKQFCHMDPRJKKMT15&marketplace=FLIPKART&store=tyy%2F4io&srno=b_1_2&otracker=browse&fm=organic&iid=en_oir18kwyq11pxxggx_gEFUtgnm_swmokR2O_kIHytUzjgtduUZKst1GRU-kDhjahE0rOwvCrrZ3p4-O1e-ClWw%3D%3D&ppt=None&ppn=None&ssid=qaei6kc2cg0000001698319478755",
        image:
          "https://www.gonnaorder.com/wp-content/uploads/2021/08/GoCustomer-Home_google-pixel5-sortasage-portrait.png",
        price: "20000",
      },
      {
        name: "apple",
        url: "https://www.flipkart.com/apple-iphone-14-pro-max-deep-purple-128-gb/p/itm5256789ae40c7",
        image:
          "https://www.gonnaorder.com/wp-content/uploads/2021/08/GoCustomer-Home_google-pixel5-sortasage-portrait.png",
        price: "130000",
      },
      {
        name: "oneplus",
        url: "https://www.amazon.in/OnePlus-Emerald-Forest-128GB-Storage/dp/B09V2LX1R4?source=ps-sl-shoppingads-lpcontext&ref_=fplfs&psc=1&smid=AQUYM0O99MFUT",
        image:
          "https://www.gonnaorder.com/wp-content/uploads/2021/08/GoCustomer-Home_google-pixel5-sortasage-portrait.png",
        price: "50000",
      },
      {
        name: "samsung",
        url: "https://www.flipkart.com/samsung-galaxy-f54-5g-stardust-silver-256-gb/p/itme2f1ad33150df?pid=MOBGPN55PEBUKZX2&lid=LSTMOBGPN55PEBUKZX25RULZW&marketplace=FLIPKART&fm=neo%2Fmerchandising&iid=M_71cc7394-fe12-46c3-9fff-5946aa87d132_1_1BUWY8OBA8L9_MC.MOBGPN55PEBUKZX2&ppt=pp&ppn=pp&ssid=4wb3uo6r3k0000001698319635357&otracker=clp_pmu_v2_Latest%2BSamsung%2Bmobiles%2B_1_1.productCard.PMU_V2_SAMSUNG%2BGalaxy%2BF54%2B5G%2B%2528Stardust%2BSilver%252C%2B256%2BGB%2529_samsung-mobile-store_MOBGPN55PEBUKZX2_neo%2Fmerchandising_0&otracker1=clp_pmu_v2_PINNED_neo%2Fmerchandising_Latest%2BSamsung%2Bmobiles%2B_LIST_productCard_cc_1_NA_view-all&cid=MOBGPN55PEBUKZX2",
        image:
          "https://www.gonnaorder.com/wp-content/uploads/2021/08/GoCustomer-Home_google-pixel5-sortasage-portrait.png",
        price: "25000",
      },
      {
        name: "oppo",
        url: "https://www.flipkart.com/oppo-reno-10-ice-blue-256-gb/p/itm9b49485a7c93e?pid=MOBGQP3UUYGFGYKD&param=398765",
        image:
          "https://www.gonnaorder.com/wp-content/uploads/2021/08/GoCustomer-Home_google-pixel5-sortasage-portrait.png",
        price: "30000",
      },
      {
        name: "poco",
        url: "https://www.flipkart.com/poco-x5-pro-5g-yellow-256-gb/p/itm3a4010e6b2c40?pid=MOBGMDKQFCHMDPRJ&lid=LSTMOBGMDKQFCHMDPRJKKMT15&marketplace=FLIPKART&store=tyy%2F4io&srno=b_1_2&otracker=browse&fm=organic&iid=en_oir18kwyq11pxxggx_gEFUtgnm_swmokR2O_kIHytUzjgtduUZKst1GRU-kDhjahE0rOwvCrrZ3p4-O1e-ClWw%3D%3D&ppt=None&ppn=None&ssid=qaei6kc2cg0000001698319478755",
        image:
          "https://www.gonnaorder.com/wp-content/uploads/2021/08/GoCustomer-Home_google-pixel5-sortasage-portrait.png",
        price: "20000",
      },
      {
        name: "apple",
        url: "https://www.flipkart.com/apple-iphone-14-pro-max-deep-purple-128-gb/p/itm5256789ae40c7",
        image:
          "https://www.gonnaorder.com/wp-content/uploads/2021/08/GoCustomer-Home_google-pixel5-sortasage-portrait.png",
        price: "130000",
      },
      {
        name: "oneplus",
        url: "https://www.amazon.in/OnePlus-Emerald-Forest-128GB-Storage/dp/B09V2LX1R4?source=ps-sl-shoppingads-lpcontext&ref_=fplfs&psc=1&smid=AQUYM0O99MFUT",
        image:
          "https://www.gonnaorder.com/wp-content/uploads/2021/08/GoCustomer-Home_google-pixel5-sortasage-portrait.png",
        price: "50000",
      },
      {
        name: "samsung",
        url: "https://www.flipkart.com/samsung-galaxy-f54-5g-stardust-silver-256-gb/p/itme2f1ad33150df?pid=MOBGPN55PEBUKZX2&lid=LSTMOBGPN55PEBUKZX25RULZW&marketplace=FLIPKART&fm=neo%2Fmerchandising&iid=M_71cc7394-fe12-46c3-9fff-5946aa87d132_1_1BUWY8OBA8L9_MC.MOBGPN55PEBUKZX2&ppt=pp&ppn=pp&ssid=4wb3uo6r3k0000001698319635357&otracker=clp_pmu_v2_Latest%2BSamsung%2Bmobiles%2B_1_1.productCard.PMU_V2_SAMSUNG%2BGalaxy%2BF54%2B5G%2B%2528Stardust%2BSilver%252C%2B256%2BGB%2529_samsung-mobile-store_MOBGPN55PEBUKZX2_neo%2Fmerchandising_0&otracker1=clp_pmu_v2_PINNED_neo%2Fmerchandising_Latest%2BSamsung%2Bmobiles%2B_LIST_productCard_cc_1_NA_view-all&cid=MOBGPN55PEBUKZX2",
        image:
          "https://www.gonnaorder.com/wp-content/uploads/2021/08/GoCustomer-Home_google-pixel5-sortasage-portrait.png",
        price: "25000",
      },
    ],
  },
  {
    name: "Laptops",
    data: [
      {
        name: "Dell",
        url: "https://www.amazon.in/Dell-i3-1115G4-Processor-39-62cm-Anti-Glare/dp/B0CCSPWCQY/ref=sr_1_1_sspa?adgrpid=140360389887&hvadid=598082394863&hvdev=c&hvlocphy=1007740&hvnetw=g&hvqmt=e&hvrand=10201215242254324763&hvtargid=kwd-667380588451&hydadcr=14216_1731109&keywords=laptops%27&qid=1698319756&s=computers&sr=1-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1",
        image:
          "https://assets.newatlas.com/dims4/default/462a95e/2147483647/strip/true/crop/5760x3840+0+0/resize/2880x1920!/quality/90/?url=http%3A%2F%2Fnewatlas-brightspot.s3.amazonaws.com%2F54%2F5f%2F61ef624a4a85a8fbc2e900b80233%2Fframework-laptop-16-hero01.jpg",
        price: "45000",
      },
      {
        name: "HP",
        url: "https://www.amazon.in/Dell-i3-1115G4-Processor-39-62cm-Anti-Glare/dp/B0CCSPWCQY/ref=sr_1_1_sspa?adgrpid=140360389887&hvadid=598082394863&hvdev=c&hvlocphy=1007740&hvnetw=g&hvqmt=e&hvrand=10201215242254324763&hvtargid=kwd-667380588451&hydadcr=14216_1731109&keywords=laptops%27&qid=1698319756&s=computers&sr=1-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1",
        image:
          "https://assets.newatlas.com/dims4/default/462a95e/2147483647/strip/true/crop/5760x3840+0+0/resize/2880x1920!/quality/90/?url=http%3A%2F%2Fnewatlas-brightspot.s3.amazonaws.com%2F54%2F5f%2F61ef624a4a85a8fbc2e900b80233%2Fframework-laptop-16-hero01.jpg",
        price: "40000",
      },
      {
        name: "MSI",
        url: "https://www.amazon.in/MSI-i7-1255U-Windows-Graphics-C12M-459IN/dp/B09YCX5F52/ref=sr_1_7?adgrpid=140360389887&hvadid=598082394863&hvdev=c&hvlocphy=1007740&hvnetw=g&hvqmt=e&hvrand=10201215242254324763&hvtargid=kwd-667380588451&hydadcr=14216_1731109&keywords=laptops%27&qid=1698319756&s=computers&sr=1-7",
        image:
          "https://assets.newatlas.com/dims4/default/462a95e/2147483647/strip/true/crop/5760x3840+0+0/resize/2880x1920!/quality/90/?url=http%3A%2F%2Fnewatlas-brightspot.s3.amazonaws.com%2F54%2F5f%2F61ef624a4a85a8fbc2e900b80233%2Fframework-laptop-16-hero01.jpg",
        price: "35000",
      },
      {
        name: "lenovo",
        url: "https://www.amazon.in/Lenovo-1920x1080-Antiglare-300Nits-82TW001DIH/dp/B0CH8D6F4N/ref=sr_1_11?adgrpid=140360389887&hvadid=598082394863&hvdev=c&hvlocphy=1007740&hvnetw=g&hvqmt=e&hvrand=10201215242254324763&hvtargid=kwd-667380588451&hydadcr=14216_1731109&keywords=laptops%27&qid=1698319756&s=computers&sr=1-11&th=1",
        image:
          "https://assets.newatlas.com/dims4/default/462a95e/2147483647/strip/true/crop/5760x3840+0+0/resize/2880x1920!/quality/90/?url=http%3A%2F%2Fnewatlas-brightspot.s3.amazonaws.com%2F54%2F5f%2F61ef624a4a85a8fbc2e900b80233%2Fframework-laptop-16-hero01.jpg",
        price: "42000",
      },
      {
        name: "Dell",
        url: "https://www.amazon.in/Dell-i3-1115G4-Processor-39-62cm-Anti-Glare/dp/B0CCSPWCQY/ref=sr_1_1_sspa?adgrpid=140360389887&hvadid=598082394863&hvdev=c&hvlocphy=1007740&hvnetw=g&hvqmt=e&hvrand=10201215242254324763&hvtargid=kwd-667380588451&hydadcr=14216_1731109&keywords=laptops%27&qid=1698319756&s=computers&sr=1-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1",
        image:
          "https://assets.newatlas.com/dims4/default/462a95e/2147483647/strip/true/crop/5760x3840+0+0/resize/2880x1920!/quality/90/?url=http%3A%2F%2Fnewatlas-brightspot.s3.amazonaws.com%2F54%2F5f%2F61ef624a4a85a8fbc2e900b80233%2Fframework-laptop-16-hero01.jpg",
        price: "45000",
      },
      {
        name: "HP",
        url: "https://www.amazon.in/Dell-i3-1115G4-Processor-39-62cm-Anti-Glare/dp/B0CCSPWCQY/ref=sr_1_1_sspa?adgrpid=140360389887&hvadid=598082394863&hvdev=c&hvlocphy=1007740&hvnetw=g&hvqmt=e&hvrand=10201215242254324763&hvtargid=kwd-667380588451&hydadcr=14216_1731109&keywords=laptops%27&qid=1698319756&s=computers&sr=1-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1",
        image:
          "https://assets.newatlas.com/dims4/default/462a95e/2147483647/strip/true/crop/5760x3840+0+0/resize/2880x1920!/quality/90/?url=http%3A%2F%2Fnewatlas-brightspot.s3.amazonaws.com%2F54%2F5f%2F61ef624a4a85a8fbc2e900b80233%2Fframework-laptop-16-hero01.jpg",
        price: "40000",
      },
      {
        name: "MSI",
        url: "https://www.amazon.in/MSI-i7-1255U-Windows-Graphics-C12M-459IN/dp/B09YCX5F52/ref=sr_1_7?adgrpid=140360389887&hvadid=598082394863&hvdev=c&hvlocphy=1007740&hvnetw=g&hvqmt=e&hvrand=10201215242254324763&hvtargid=kwd-667380588451&hydadcr=14216_1731109&keywords=laptops%27&qid=1698319756&s=computers&sr=1-7",
        image:
          "https://assets.newatlas.com/dims4/default/462a95e/2147483647/strip/true/crop/5760x3840+0+0/resize/2880x1920!/quality/90/?url=http%3A%2F%2Fnewatlas-brightspot.s3.amazonaws.com%2F54%2F5f%2F61ef624a4a85a8fbc2e900b80233%2Fframework-laptop-16-hero01.jpg",
        price: "35000",
      },
      {
        name: "lenovo",
        url: "https://www.amazon.in/Lenovo-1920x1080-Antiglare-300Nits-82TW001DIH/dp/B0CH8D6F4N/ref=sr_1_11?adgrpid=140360389887&hvadid=598082394863&hvdev=c&hvlocphy=1007740&hvnetw=g&hvqmt=e&hvrand=10201215242254324763&hvtargid=kwd-667380588451&hydadcr=14216_1731109&keywords=laptops%27&qid=1698319756&s=computers&sr=1-11&th=1",
        image:
          "https://assets.newatlas.com/dims4/default/462a95e/2147483647/strip/true/crop/5760x3840+0+0/resize/2880x1920!/quality/90/?url=http%3A%2F%2Fnewatlas-brightspot.s3.amazonaws.com%2F54%2F5f%2F61ef624a4a85a8fbc2e900b80233%2Fframework-laptop-16-hero01.jpg",
        price: "42000",
      },
    ],
  },
  {
    name: "Earphones",
    data: [
      {
        name: "Boat",
        url: "https://www.amazon.in/boAt-Rockerz-255-Neo-Bluetooth/dp/B09NYK3CF2/ref=sr_1_1_sspa?crid=2DST92AP8HZQN&keywords=earphones&qid=1698319948&s=computers&sprefix=ear%2Ccomputers%2C192&sr=1-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1",
        image:
          "https://cdn1.smartprix.com/rx-ixSTCai8P-w1200-h1200/xSTCai8P.jpg",
        price: "1000",
      },
      {
        name: "JBL",
        url: "https://www.amazon.in/JBL-C100SI-Ear-Headphones-Black/dp/B01DEWVZ2C/ref=sr_1_7?crid=2DST92AP8HZQN&keywords=earphones&qid=1698319948&s=computers&sprefix=ear%2Ccomputers%2C192&sr=1-7&th=1",
        image:
          "https://www.jbl.com.sg/dw/image/v2/AAUJ_PRD/on/demandware.static/-/Sites-masterCatalog_Harman/default/dwd0871a4d/JBL_LIVE300TWS_ProductImage_White_CasewithProduct.png?sw=537&sfrm=png",
        price: "500",
      },
      {
        name: "Xiaomi",
        url: "https://www.amazon.in/Mi-Earphones-Basic-Mic-Black/dp/B07CD2BN46/ref=sr_1_10?crid=2DST92AP8HZQN&keywords=earphones&qid=1698319948&s=computers&sprefix=ear%2Ccomputers%2C192&sr=1-10&th=1",
        image:
          "https://5.imimg.com/data5/MH/TM/IR/SELLER-29518515/xiaomi-redmi-airdots-black-bluetooth-earphones-mi.jpg",
        price: "600",
      },
      {
        name: "Boat",
        url: "https://www.amazon.in/boAt-Rockerz-255-Neo-Bluetooth/dp/B09NYK3CF2/ref=sr_1_1_sspa?crid=2DST92AP8HZQN&keywords=earphones&qid=1698319948&s=computers&sprefix=ear%2Ccomputers%2C192&sr=1-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1",
        image:
          "https://cdn1.smartprix.com/rx-ixSTCai8P-w1200-h1200/xSTCai8P.jpg",
        price: "1000",
      },
      {
        name: "JBL",
        url: "https://www.amazon.in/JBL-C100SI-Ear-Headphones-Black/dp/B01DEWVZ2C/ref=sr_1_7?crid=2DST92AP8HZQN&keywords=earphones&qid=1698319948&s=computers&sprefix=ear%2Ccomputers%2C192&sr=1-7&th=1",
        image:
          "https://www.jbl.com.sg/dw/image/v2/AAUJ_PRD/on/demandware.static/-/Sites-masterCatalog_Harman/default/dwd0871a4d/JBL_LIVE300TWS_ProductImage_White_CasewithProduct.png?sw=537&sfrm=png",
        price: "500",
      },
      {
        name: "Xiaomi",
        url: "https://www.amazon.in/Mi-Earphones-Basic-Mic-Black/dp/B07CD2BN46/ref=sr_1_10?crid=2DST92AP8HZQN&keywords=earphones&qid=1698319948&s=computers&sprefix=ear%2Ccomputers%2C192&sr=1-10&th=1",
        image:
          "https://5.imimg.com/data5/MH/TM/IR/SELLER-29518515/xiaomi-redmi-airdots-black-bluetooth-earphones-mi.jpg",
        price: "600",
      },
      {
        name: "Boat",
        url: "https://www.amazon.in/boAt-Rockerz-255-Neo-Bluetooth/dp/B09NYK3CF2/ref=sr_1_1_sspa?crid=2DST92AP8HZQN&keywords=earphones&qid=1698319948&s=computers&sprefix=ear%2Ccomputers%2C192&sr=1-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1",
        image:
          "https://cdn1.smartprix.com/rx-ixSTCai8P-w1200-h1200/xSTCai8P.jpg",
        price: "1000",
      },
      {
        name: "JBL",
        url: "https://www.amazon.in/JBL-C100SI-Ear-Headphones-Black/dp/B01DEWVZ2C/ref=sr_1_7?crid=2DST92AP8HZQN&keywords=earphones&qid=1698319948&s=computers&sprefix=ear%2Ccomputers%2C192&sr=1-7&th=1",
        image:
          "https://www.jbl.com.sg/dw/image/v2/AAUJ_PRD/on/demandware.static/-/Sites-masterCatalog_Harman/default/dwd0871a4d/JBL_LIVE300TWS_ProductImage_White_CasewithProduct.png?sw=537&sfrm=png",
        price: "500",
      },
      {
        name: "Xiaomi",
        url: "https://www.amazon.in/Mi-Earphones-Basic-Mic-Black/dp/B07CD2BN46/ref=sr_1_10?crid=2DST92AP8HZQN&keywords=earphones&qid=1698319948&s=computers&sprefix=ear%2Ccomputers%2C192&sr=1-10&th=1",
        image:
          "https://5.imimg.com/data5/MH/TM/IR/SELLER-29518515/xiaomi-redmi-airdots-black-bluetooth-earphones-mi.jpg",
        price: "600",
      },
    ],
  },
  {
    name: "Watches",
    data: [
      {
        name: "FIREBOLT",
        url: "https://www.amazon.in/Fire-Boltt-Bluetooth-Smartwatch-Assistant-Monitoring/dp/B0BRKXXPZ7/ref=sr_1_1_sspa?crid=30XNNK5XIKYTC&keywords=smart%2Bwatch%2Bfor%2Bmen&qid=1698320073&s=computers&sprefix=wa%2Ccomputers%2C188&sr=1-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1",
        image:
          "https://www.fireboltt.com/cdn/shop/files/cyclone-black_1.png?v=1687418762",
        price: "1100",
      },
      {
        name: "BOAT",
        url: "https://www.amazon.in/boAt-Call-Plus-Advanced-Languages/dp/B0C1YWFHK5/ref=sr_1_6?crid=30XNNK5XIKYTC&keywords=smart%2Bwatch%2Bfor%2Bmen&qid=1698320073&s=computers&sprefix=wa%2Ccomputers%2C188&sr=1-6&th=1",
        image:
          "https://www.boat-lifestyle.com/cdn/shop/products/3-2.png?v=1693893554",
        price: "899",
      },
      {
        name: "Oneplus",
        url: "https://www.amazon.in/OnePlus-Display-Refresh-Fitness-Multiple/dp/B0BD93V6RY/ref=sr_1_28?crid=30XNNK5XIKYTC&keywords=smart%2Bwatch%2Bfor%2Bmen&qid=1698320073&s=computers&sprefix=wa%2Ccomputers%2C188&sr=1-28&th=1",
        image:
          "https://image01.oneplus.net/ebp/202103/12/1-m00-21-ed-rb8bwmbk1wgadz8_aai9rijgk7q405.png",
        price: "1500",
      },
      {
        name: "FIREBOLT",
        url: "https://www.amazon.in/Fire-Boltt-Bluetooth-Smartwatch-Assistant-Monitoring/dp/B0BRKXXPZ7/ref=sr_1_1_sspa?crid=30XNNK5XIKYTC&keywords=smart%2Bwatch%2Bfor%2Bmen&qid=1698320073&s=computers&sprefix=wa%2Ccomputers%2C188&sr=1-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1",
        image:
          "https://www.fireboltt.com/cdn/shop/files/cyclone-black_1.png?v=1687418762",
        price: "1100",
      },
      {
        name: "BOAT",
        url: "https://www.amazon.in/boAt-Call-Plus-Advanced-Languages/dp/B0C1YWFHK5/ref=sr_1_6?crid=30XNNK5XIKYTC&keywords=smart%2Bwatch%2Bfor%2Bmen&qid=1698320073&s=computers&sprefix=wa%2Ccomputers%2C188&sr=1-6&th=1",
        image:
          "https://www.boat-lifestyle.com/cdn/shop/products/3-2.png?v=1693893554",
        price: "899",
      },
      {
        name: "Oneplus",
        url: "https://www.amazon.in/OnePlus-Display-Refresh-Fitness-Multiple/dp/B0BD93V6RY/ref=sr_1_28?crid=30XNNK5XIKYTC&keywords=smart%2Bwatch%2Bfor%2Bmen&qid=1698320073&s=computers&sprefix=wa%2Ccomputers%2C188&sr=1-28&th=1",
        image:
          "https://image01.oneplus.net/ebp/202103/12/1-m00-21-ed-rb8bwmbk1wgadz8_aai9rijgk7q405.png",
        price: "1500",
      },
      {
        name: "FIREBOLT",
        url: "https://www.amazon.in/Fire-Boltt-Bluetooth-Smartwatch-Assistant-Monitoring/dp/B0BRKXXPZ7/ref=sr_1_1_sspa?crid=30XNNK5XIKYTC&keywords=smart%2Bwatch%2Bfor%2Bmen&qid=1698320073&s=computers&sprefix=wa%2Ccomputers%2C188&sr=1-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1",
        image:
          "https://www.fireboltt.com/cdn/shop/files/cyclone-black_1.png?v=1687418762",
        price: "1100",
      },
      {
        name: "BOAT",
        url: "https://www.amazon.in/boAt-Call-Plus-Advanced-Languages/dp/B0C1YWFHK5/ref=sr_1_6?crid=30XNNK5XIKYTC&keywords=smart%2Bwatch%2Bfor%2Bmen&qid=1698320073&s=computers&sprefix=wa%2Ccomputers%2C188&sr=1-6&th=1",
        image:
          "https://www.boat-lifestyle.com/cdn/shop/products/3-2.png?v=1693893554",
        price: "899",
      },
      {
        name: "Oneplus",
        url: "https://www.amazon.in/OnePlus-Display-Refresh-Fitness-Multiple/dp/B0BD93V6RY/ref=sr_1_28?crid=30XNNK5XIKYTC&keywords=smart%2Bwatch%2Bfor%2Bmen&qid=1698320073&s=computers&sprefix=wa%2Ccomputers%2C188&sr=1-28&th=1",
        image:
          "https://image01.oneplus.net/ebp/202103/12/1-m00-21-ed-rb8bwmbk1wgadz8_aai9rijgk7q405.png",
        price: "1500",
      },
    ],
  },
];
