import React from "react";
import styles from "./styles.module.css";
import { useLocation } from "react-router";
const List = () => {
  const location = useLocation();
  const listData = location.state;
  return (
    <div className={styles.containerStyle}>
      <div className={styles.headerTextViewStyle}>
        <p className={styles.headerStyle}>{listData.name}</p>
      </div>
      <div className={styles.listViewStyle}>
        {listData.data.map((item, index) => (
          <div className={styles.cardViewStyle}>
            <div className={styles.imageViewStyle}>
              <img src={item.image} alt="image" className={styles.imageStyle} />
            </div>
            <p className={styles.titleStyle}>{item.name}</p>
            <p className={styles.titleStyle}>${item.price}</p>
            <button
              onClick={() => window.open(item.url)}
              className={styles.btnStyle}
            >
              Buy
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
export default List;
