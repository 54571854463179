import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Home from "../pages/Home";
import List from "../pages/list";
const Navigation = () => {
  return useRoutes([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/list",
      element: <List />,
    },
  ]);
};
export default Navigation;
